<template>
    <el-form ref="form" :model="form" label-width="140px" class="elForm" :rules="rules">
        <el-form-item label="活动分类：" prop="cate_id">
            <el-select v-model="form.cate_id" style="width: 700px">
                <el-option v-for="item in classify" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="活动标题：" prop="title">
            <el-input placeholder="请输入活动标题" type="text" v-model="form.title" style="width: 700px;"></el-input>
        </el-form-item>
        <el-form-item label="活动区域：" prop="area">
            <el-cascader v-model="form.area" :options="allAreaList" style="width: 700px;"
                :props="{ value: 'id', label: 'name', children: '_child' }"></el-cascader>
        </el-form-item>
        <el-form-item label="活动地址：" prop="address">
            <el-input placeholder="请输入活动地址" type="text" v-model="form.address" style="width: 700px;"></el-input>
        </el-form-item>
        <el-form-item label="活动时间：" prop="time">
            <el-date-picker style="width: 700px;" v-model="form.time" type="datetimerange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="活动奖励积分/人：" prop="integral">
            <el-input type="text" v-model="form.integral" style="width: 700px;">
                <template slot="append">积分/人</template>
            </el-input>
            <span class="tips">如果有特殊奖励，在活动参与列表中，找出对应的用户，直接奖励积分</span>
        </el-form-item>
        <el-form-item label="活动图片：" prop="image">
            <ReadyUploadSource @getSource="val => (form.image = val.path)" :path="form.image"
                @removeThis="() => (form.image = '')">
            </ReadyUploadSource>
        </el-form-item>
        <el-form-item label="活动详情：" prop="detail">
            <RichText :richTxt="form.detail" @soninfo="val => (form.detail = val)"></RichText>
        </el-form-item>
        <Preservation @preservation="onSubmit"></Preservation>
    </el-form>
</template>

<script>
import RichText from '@/components/richText';
import Preservation from '@/components/preservation';
import ReadyUploadSource from '@/components/readyUploadSource';
export default {
    components: {
        RichText,
        Preservation,
        ReadyUploadSource
    },
    data () {
        return {
            id: '',
            classify: [],
            form: {
                cate_id: '',
                title: '',
                province_id: '',
                city_id: '',
                address: '',
                start_time: '',
                end_time: '',
                image: '',
                detail: '',
                sort: 0,
                integral: '',

                area: [],
                time: ''
            },
            rules: {
                title: [
                    { required: true, message: '请输入活动标题', trigger: 'blur' },
                ],
                area: [
                    { required: true, message: '请选择活动区域', trigger: 'change' },
                ],
                address: [
                    { required: true, message: '请输入活动地址', trigger: 'blur' },
                ],
                time: [
                    { required: true, message: '请选择活动时间', trigger: 'change' },
                ],
                integral: [
                    { required: true, message: '请输入活动奖励积分/人', trigger: 'blur' },
                ],
                image: [
                    { required: true, message: '请上传活动图片', trigger: 'change' },
                ],
                detail: [
                    { required: true, message: '请填写活动详情', trigger: 'change' },
                ]
            },
            is_edit: false,
            allAreaList: []
        };
    },
    async created () {
        await this.getClassify()
        await this.getArea()
        if (this.$route.query.info) {
            let info = JSON.parse(this.$route.query.info)
            this.form = {
                title: info.title,
                province_id: info.province_id,
                city_id: info.city_id,
                address: info.address,
                start_time: info.start_time,
                end_time: info.end_time,
                image: info.image,
                detail: info.detail,
                id: info.id,
                cate_id: info.cate_id,
                sort: info.sort,
                integral: info.integral,

                area: [info.province_id, info.city_id],
                time: [info.start_time * 1000, info.end_time * 1000]
            }
            this.is_edit = true
        }
    },
    methods: {
        // 获取省市区信息
        getArea () {
            this.$axios
                .get(this.$api.shopDiy.areaList, {
                    tree: 1,
                    level: 1,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.allAreaList = res.result.list;
                    }
                });
        },
        getClassify () {
            this.$axios.post(this.$api.repair.marketing.ActivityCateList).then(res => {
                if (res.code == 0) {
                    this.classify = res.result.list;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        onSubmit () {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let form = { ...this.form }
                    form.start_time = this.form.time[0] / 1000
                    form.end_time = this.form.time[1] / 1000
                    delete form.time

                    form.province_id = this.form.area[0]
                    form.city_id = this.form.area[1]
                    delete form.area

                    let url = this.is_edit ? this.$api.repair.marketing.ActivityEdit : this.$api.repair.marketing.ActivityAdd

                    this.$axios.post(url, form).then(res => {
                        if (res.code == 0) {
                            this.$message.success((this.is_edit ? '编辑' : '添加') + '成功');
                            this.$router.go(-1)
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.elForm {
    background-color: #fff;
    padding: 30px 30px 80px;
}

.centerBtn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tips {
    color: #999;
    margin-left: 20px;
}
</style>
